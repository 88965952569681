<header class="header" [ngClass]="{ sticky: isSticky() }">
  <section class="topbar">
    <div class="container">
      <div class="topbar__flex">
        <a [routerLink]="['/news']" class="logo"></a>
        <div class="navigation__items">
          <a
            href="{{ dashboardUrl }}"
            class="navigation__link"
            routerLinkActive="navigation__link--active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="navigation__link-inner">{{ "Navigation.Dashboard" | translate }}</span>
          </a>
          <a class="navigation__link">&nbsp;|&nbsp;</a>
          <a href="{{ newsUrl }}" class="navigation__link navigation__link--active">
            <span class="navigation__link-inner">{{ "Navigation.News" | translate }}</span>
          </a>
        </div>
        <div class="topbar__items">
          <!-- Reenable when we want to implement languaes -->
          <div class="topbar__item__wrapper">
            <span class="topbar__language" *ngFor="let lang of translate.getLangs()">
              <span
                (click)="switchLanguage(lang)"
                [routerLink]="['/news', lang]"
                [class.topbar__language--active]="translate.currentLang === lang"
              >
                {{ lang }}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="navigation" [class.fixed]="false">
    <div class="container">
      <div class="navigation__flex">
        <div class="navigation__text--wrapper">
          <span class="navigation__icon navigation__icon__news">
            <svg-icon src="assets/images/icons/news.svg" class="service-tile__icon"></svg-icon>
          </span>
          <span class="navigation__text">
            <ng-template #notChildRoute>
              <span class="navigation__text--top">
                {{ "Navigation.News" | translate }}
              </span>
            </ng-template>
            <button
              *ngIf="isChildRoute(); else notChildRoute"
              (click)="goBack()"
              class="navigation__text--top navigation__back"
            >
              <svg-icon src="assets/images/icons/icon-arrow-left.svg" class="back-button"> </svg-icon>
              {{ "Global.Action.Back" | translate }}
            </button>

            <span class="navigation__text--main" [innerHTML]="forceTitle() ? forceTitle() : mainNavText()"></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</header>
